import React, { useEffect } from "react";
import "./ProfilePage.css";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import { SocialIcon } from 'react-social-icons';
import { getClosedTradesByUserId } from "../../helpers/api";

export const ProfilePage = () => {

    const userId = "1724013894168";

    const fetchDataForProfilePage = async () => {
        return Promise.all([
            getClosedTradesByUserId(userId)
        ]).then(([closedTradeData]) => {
            // Check Return Object
            if (Array.isArray(closedTradeData) && closedTradeData.length > 0) {
                console.log(closedTradeData);
            }
        })
    }

    const OpenTradingViewProfile = () => {
        const url = "https://www.tradingview.com/u/rileybusche/";
        window.open(url, "_blank", "noopener,noreferrer");
    }

    useEffect(() => {
        fetchDataForProfilePage();
    },[]);

    return(
        <DashboardLayout page={"Profile"}>
            <div className="profile-page-content">
                <div className="profile-page-username-container">

                    <AccountCircleIcon sx={{fontSize: 100}} className="user-hero-icon"/>

                    <div className="profile-page-user-info">
                        <h1 className="text-header">rileybusche</h1>
                        <div className="profile-page-user-info-tv">
                            <InsertLinkRoundedIcon />
                            <p className="text-base" onClick={OpenTradingViewProfile}>TradingView</p>
                        </div>
                        <p className="text-base"> 27.3% avg profit (7 days)</p>
                    </div>

                    <div className="profile-page-social-container">
                        <SocialIcon url="https://x.com" />
                        <SocialIcon url="https://telegram.com" />
                        <SocialIcon url="https://www.tradingview.com" />
                    </div>

                    
                </div>
                <div className="profile-page-description glass-surface-module">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>

                <div className="profile-page-closed-trades glass-surface-module">
                    <h1 className="text-header">Closed Trades (7 days)</h1>
                </div>

                <div className="profile-page-current-portfolio glass-surface-module">
                    <h1 className="text-header">Current Portfolio</h1>
                </div>
            </div>
        </DashboardLayout>
    );
};