import React, { useState } from "react";
import BarChart from "../nivoCharts/BarChart";

import InvestedCardModal from "./modals/InvestedCardModal";

import { useTheme } from "../../hooks/useTheme";

const InvestedCard = ({ userBalance, openPositionData }) => {
  const [modalState, setModalState] = useState(false);

  const { isMobile } = useTheme();

  const chartSettings = {
    margin: { top: 10, right: 20, bottom: 25, left: 60 },
  };
  var portfolioValue = 0;
  openPositionData.forEach((token) => {
    portfolioValue += (token.amount * token.currentPrice);
  });

  const chartData = [
    {
      capital: "Invested",
      invested: portfolioValue,
      color: "#00A652",
    },
    {
      capital: "Available",
      available: parseFloat(userBalance),
      color: "#ED1B24"
    },
  ];

  const toggleModal = () => {
    setModalState(!modalState);
  };
  return (
    <>
      <div className="card glass-surface-module" onClick={() => !isMobile && toggleModal()}>
        {/* <h2 className="card-title">Total Invested (USD)</h2> */}
        <h1 className="text-header text-primary">Invested</h1>
        <div className="card-info">
          <div className="card-position-info">
            <h1 className="card-position-value text-base">
              {portfolioValue.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
              <span className="text-base"> in crypto</span>
            </h1>
          </div>
          {/* Nivo BarGraph here for invested */}
          <div className="card-chart-display">
            <BarChart chartData={chartData} chartSettings={chartSettings} />
          </div>
        </div>
      </div>
      {modalState && (
        <InvestedCardModal
          chartData={chartData}
          portfolioValue={portfolioValue}
          walletBalance={userBalance}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};

export default InvestedCard;
