import React from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";

import "./StrategiesPage.css"

export const StrategiesPage = () => {
    return(
        <DashboardLayout page={"Strategies"}>
            <div className="strategies-content">
                <div className="strategies-grid">
                    <div className="strategy-card glass-surface-module">
                        Test
                    </div>
                    <div className="strategy-card glass-surface-module">
                        Test
                    </div>
                    <div className="strategy-card glass-surface-module">
                        Test
                    </div>
                    <div className="strategy-card glass-surface-module">
                        Test
                    </div>
                    <div className="strategy-card glass-surface-module">
                        Test
                    </div>  
                </div>
            </div>
        </DashboardLayout>
    )
}