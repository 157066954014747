import React from "react";
import Spinner from "../loadingAnimations/Spinner";
import "./OpenPositions.css";

export const OpenPositionsSpinner = () => {
    return(
        <div className="open-positions glass-surface-module">
            <Spinner spinnerColor="grey" />
        </div>
    );
}