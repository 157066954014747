import React, { useState } from "react";

import "./TopBar.css";
import PersonIcon from "@mui/icons-material/Person";
// import SettingsIcon from "@mui/icons-material/Settings";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import Brightness4Icon from '@mui/icons-material/Brightness4';

import { UserInfoModal } from "../ModalController/UserInfoModal";

import { useTheme } from "../../hooks/useTheme";
import { DiscordIcon } from "../CustomIcons/DiscordIcon";
// import { CoinPriceMarquee } from "../CoinMarketCapWidget/CoinPriceMarquee";

const TopBar = ({page}) => {

  const [showUserInfoModal, setShowUserInfoModal] = useState(false);

  const handleModalClose = () => {
    setShowUserInfoModal(false);
  };

  const handleDiscord = () => {
    window.open(
      "https://discord.gg/5M4CbvdMVd",
      '_blank'
    );
  };
 
  const { toggleDarkMode, toggleSideBar } = useTheme();

  return (
    <>
      <div className="topbar-wrapper glass-surface">
        <div className="topbar-container">
          <h2 className="topbar-title text-header">{page}</h2>
          <div className="topbar-button-container">
            <DiscordIcon className="topbar-icon" onClick={handleDiscord}/>
            <AutoAwesomeMosaicIcon className="topbar-icon" onClick={() => toggleSideBar()}/>
            <Brightness4Icon className="topbar-icon" onClick={toggleDarkMode}/>
            {/* <NotificationsIcon className="topbar-icon" /> */}
            {/* <SettingsIcon className="topbar-icon" /> */}
            <PersonIcon
              className="topbar-icon"
              onClick={() => setShowUserInfoModal(true)}
            />
          </div>
        </div>
        {/* <hr className="topbar-divider" /> */}
        {/* <CoinPriceMarquee /> */}
      </div>
      {showUserInfoModal && <UserInfoModal handleModalClose={handleModalClose}/>}
    </>
  );
};

export default TopBar;
