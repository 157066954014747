import "./styles/App.css";

import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard/Dashboard";
import ContactUs from "./pages/ContactUs/ContactUs";
import AccountInfo from "./pages/AccountInfo/AccountInfo";
import TradeHistory from "./pages/TradeHistory/TradeHistory";

import ManualTrade from "./pages/ManualTrade/ManualTrade";
import LandingPage from "./pages/LandingPage/LandingPage";
import WebhookUrlInstructions from "./pages/Webhook/WebhookUrlInstructions";
import DashboardContext from "./context/DashboardContext";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import { ProtectedRoute } from "./components/PrivateRoute";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ReactGA from "react-ga4";
import { Leaderboard } from "./pages/Leaderboard/Leaderboard";
import { StrategiesPage } from "./pages/Strategies/Strategies";
import { ProfilePage } from "./pages/ProfilePage/ProfilePage";

ReactGA.initialize("G-9GGVQ1W15Y");

function App() {

  /* Dashboard State */
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [isSideBarExpanded, setIsSideBarExpanded] = useState(true);
  const value = {
    isLoginVisible,
    setIsLoginVisible,
    isSideBarExpanded,
    setIsSideBarExpanded,
  };

  return (
    <div className="App">
        <DashboardContext.Provider value={value}>
          <Routes>
            <Route
              path="/"
              element={
                <LandingPage />
              }
            />
            <Route
              path="/login"
              element={
                <Login />
              }
            />
            <Route
              path="/signup"
              element={
                <Signup />
              }
            />
            <Route 
              path="/forgot-password"
              element={
                <ForgotPassword />
              }
            />
            <Route
              path="/reset-password"
              element={
                <ResetPassword />
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="/feed"
              element={
                <ProtectedRoute>
                  <Feed />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manualtrades"
              element={
                <ProtectedRoute>
                  <ManualTrade />  
                </ProtectedRoute>
              }
            />
            <Route
              path="/history"
              element={
                <ProtectedRoute>
                  <TradeHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/leaderboard"
              element={
                <ProtectedRoute>
                  <Leaderboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/strategies"
              element={
                <ProtectedRoute>
                  <StrategiesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/webhook"
              element={
                <ProtectedRoute>
                  <WebhookUrlInstructions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <AccountInfo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contact"
              element={
                <ProtectedRoute>
                  <ContactUs />
                </ProtectedRoute>
              }
            />
          </Routes>
        </DashboardContext.Provider>
    </div>
  );
}

export default App;
