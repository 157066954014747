import React, { useEffect, useState } from 'react';
import "./TradingViewWidgetModal.css";

import TradeAction from '../../pages/ManualTrade/TradeAction';
import TradeOrderInput from '../../pages/ManualTrade/TradeOrderInput';

import { getCoinBaseTokenPrice, getUserBalance, manualTrade } from '../../helpers/api';
import { useAuth } from '../../hooks/useAuth';
import { ChartModal } from './ChartModal';
import Spinner from '../loadingAnimations/Spinner';
import Button from '../primitives/Button/Button';

const TradingViewWidgetModal = ({tokenId, setShowModal, openPositionData, refreshDashboardData}) => {
    // console.log(openPositionData)
    // console.log(tokenId)
    
    const [tradeOption, setTradeOption] = useState("buy");
    const [tokenPrice, setTokenPrice] = useState(null);

    const [userTokenAmount, setUserTokenAmount] = useState(0);
    const [userBalance, setUserBalance] = useState(0);
    const [isUSD, setIsUSD] = useState(true);

    const [tradeAmountUSD, setTradeAmountUSD] = useState(0);
    const [tradeAmountCrypto, setTradeAmountCrypto] = useState(0);

    const [isInputValid, setIsInputValid] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [showTradeResponseStatus, setShowTradeResponseStatus] = useState(false);
    const [tradeStatusMessage, setTradeStatusMessage] = useState(null);

    const { user } = useAuth();

    const handleAmountChange = (amount, currencyType) => {
        if (currencyType === "USD") {
          setTradeAmountUSD(amount);
          setTradeAmountCrypto(amount/tokenPrice);
        } else if (currencyType === "CRYPTO") {
          setTradeAmountUSD(amount * tokenPrice);
          setTradeAmountCrypto(amount);
        }
    }

    const handleOrderSubmit = async () => {
        if (isInputValid && tradeAmountCrypto > 0) {
            setShowSpinner(true);
      
            const tradeData = {
                tokenpair: tokenId + "USD",
                count: tradeAmountCrypto,
                usdprice: tokenPrice,
                action: tradeOption,
                exchange: "Coinbase",
                user: user.userId,
                pin: user.webhookId,
            };

            return Promise.all([
                manualTrade(tradeData)
            ]).then(([tradeResponse]) => {
                setShowSpinner(false);

                setShowTradeResponseStatus(true);
                setTradeStatusMessage(tradeResponse.status === 200 ? "Sucess!" : "Server Error");

                setTimeout(() => {
                    setShowTradeResponseStatus(false);
                    setTradeStatusMessage(null);
                    tradeResponse.status === 200 && setShowModal(false);
                }, 2000);

                if (tradeResponse && tradeResponse.status === 200) {

                    // console.log(tradeResponse);
                    if (tradeOption === "buy") {
                        setUserTokenAmount(userTokenAmount+tradeAmountCrypto);
                        setUserBalance(parseFloat(userBalance - tradeAmountUSD));

                    } else {
                        // Updating user holdings to update info in TradeOrderInput
                        setUserTokenAmount(userTokenAmount - tradeAmountCrypto);
                        setUserBalance(parseFloat(userBalance + tradeAmountUSD));
                    }
                    // Resetting the amount field after buy/sell
                    setTradeAmountCrypto(0);
                    setTradeAmountUSD(0);
                    // trigger data fetch in dashboard 
                    refreshDashboardData();
                }

            });
        }
    };

    useEffect(() => {
        setTradeAmountCrypto(0);
        setTradeAmountUSD(0);
    }, [tradeOption])

    useEffect(() => {
        const fetchUserHoldings = async () => {
            // this will call both API calls at once and await a return of the promise befor proceeding into the .then
            return Promise.all([
                getCoinBaseTokenPrice(tokenId),
                getUserBalance(user.userId),
            ]).then(([coinbaseResponse, userBalance]) => {
                // Deconstructing the reutrn array from .all into the data that is returned from the functions.
                if (coinbaseResponse.status === 200) {
                    // console.log(coinbaseResponse);
                    setTokenPrice(parseFloat(coinbaseResponse.data.data.amount));
                }
                setUserBalance(parseFloat(userBalance));
            });
        };

        if (user) {
            fetchUserHoldings().then();
        }
        if (openPositionData) {
            openPositionData.forEach(token => {
                if (token.id === tokenId) {
                    setUserTokenAmount(token.amount);
                }
            });
        }
    }, [user, openPositionData, tokenId])

    return (
        <div className="tv-widget-modal" onClick={() => setShowModal(false)}>

            <div className="tv-widget-grid-layout" onClick={(e) => e.stopPropagation()}>

                <ChartModal tokenId={tokenId} />

                <div className="tv-widget-modal-container-trade">
                    {/* Render Price data if coinbase token data is returned */}
                    {tokenPrice ? (
                        <>
                            <TradeAction 
                                tradeOption={tradeOption} 
                                setTradeOption={setTradeOption}
                            />

                            <div className="available-wrapper">
                                <div className="available-container">
                                    <h3>Available to trade</h3>
                                    <div className="available-token-wrapper">
                                    <h4>{tokenId}</h4>
                                    <p>{userTokenAmount}</p>
                                    </div>
                                    <div className="available-usd-wrapper">
                                    <h4>USD</h4>
                                    <p>{userBalance.toLocaleString("en-US", {style: "currency", currency: "USD"})}</p>
                                    </div>
                                </div>
                            </div>


                            <div className="tv-widget-trade-information-wrapper">
                                <TradeOrderInput 
                                    tradeOption={tradeOption}
                                    userTokenAmount={userTokenAmount}
                                    isReadOnly={false}
                                    tokenChoice={tokenId}
                                    tokenPrice={tokenPrice}
                                    userBalance={userBalance}
                                    amount={isUSD ? tradeAmountUSD : tradeAmountCrypto}
                                    tradeAmountCrypto={tradeAmountCrypto}
                                    isUSD={isUSD}
                                    setIsUSD={setIsUSD}
                                    handleAmountChange={handleAmountChange}
                                    isInputValid={isInputValid}
                                    setIsInputValid={setIsInputValid}
                                />
                            </div>
                            <div className="trade-button-wrapper">
                                <div className="trade-button-container">

                                    <div className="trade-total-wrapper">
                                        <h4>{tokenId}*</h4>
                                        {tokenPrice && <p>{tokenPrice > 1 ? `${tokenPrice.toLocaleString("en-US", {style: "currency", currency: "USD",})}` : `$${tokenPrice.toFixed(4)}`}</p>}
                                    </div>
                                    <div className="trade-total-wrapper">
                                        <h4>{tokenId} Amount</h4>
                                        <p>{tradeAmountCrypto}</p>
                                    </div>
                                    <div className="trade-total-amount">
                                        <h4>Total</h4>
                                        <p>{parseFloat(tradeAmountUSD).toLocaleString("en-US", {style: "currency", currency: "USD",})}</p>
                                    </div>
                                

                                    {!showSpinner ? (
                                        <Button kind={`trade-${tradeOption}`} onClick={handleOrderSubmit}>{tradeOption.toUpperCase()}</Button>
                                    ) : (
                                        <Button kind={`trade-${tradeOption}`}>
                                        <Spinner />
                                        </Button>
                                    )}
                                    {showTradeResponseStatus && tradeStatusMessage &&
                                        <div className="tv-widget-trade-status-alert">
                                            <p className={`text-header ${tradeStatusMessage === "Server Error" ? "text-trading-red" : ""}`}>{tradeStatusMessage}</p>
                                        </div>
                                    }
                                </div>
                            </div> 
                        </>
                    ) : (
                        <div className="tv-widget-modal-container-trade-disabled">
                            <p>Error getting token data. Please try again shortly.</p>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default TradingViewWidgetModal;