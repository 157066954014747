import React from "react";
import "./styles/Card.css";

import Spinner from "../loadingAnimations/Spinner";

export const CardSpinner = () => {
    return(
        <div className="card glass-surface-module">
            <Spinner spinnerColor="grey" />
        </div>
    );
};