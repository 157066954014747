import React from "react";

import "./Activity.css";
import BragCard from "./BragCard";
// import { ClosedTrade } from "./ClosedTrade";
import Spinner from "../loadingAnimations/Spinner";

const Activity = ({closedTrades, showClosedTradesSpinner, setShowBragCardModal, setBragCardTrade}) => {

    if (showClosedTradesSpinner) {
        return(
            <div className="activity-container glass-surface-module">
                <Spinner spinnerColor="grey"/>
            </div>
        )
    }

    if (closedTrades === null) {
        return(
            <div className="activity-container glass-surface-module">
                <div className="activity-header">
                    <h1 className="activity-title text-header text-primary-a0">Closed Trades</h1>
                </div>

                <div className="activity-closed-trade-info">
                    <h1 className="text-base">Your closed trades will show up here.</h1>
                </div>
            </div>
        );
    }

    return(
        <div className="activity-container glass-surface-module">
            <div className="activity-header">
                <h1 className="activity-title text-header text-primary">Closed Trades</h1>
            </div>

            <div className="activity-closed-trade-container">
                {closedTrades && closedTrades.length > 0 ? (
                    closedTrades.map((closedTrade, index) => (
                        <BragCard key={index} trade={closedTrade}/>

                        // <ClosedTrade trade={closedTrade} key={index} setShowBragCardModal={setShowBragCardModal} setBragCardTrade={setBragCardTrade}/>
                    ))
                ) : (
                    <h1>No Activity</h1>
                )}
            </div>
        </div>
    );

};

export default Activity;