import React from "react";
import "./LeaderboardCard.css";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const LeaderboardCard = ({cardInfo, index}) => {
    return(
        <div key={cardInfo.uid} className="leaderboard-card">

            <div className="leaderboard-card-user">

                <div className="leaderboard-card-user-info">
                    <div className="leaderboard-card-account-container">
                        <AccountCircleIcon sx={{ flex: 1, height: "100%", width: "100%", color: "grey"}} />
                    </div>
                    <div className="leaderboard-card-username">
                        <h1 className="text-small">{cardInfo.username}</h1>
                        <div className="leaderboard-card-avg-profit">
                            <p className="text-header">{cardInfo.avgProfit.toFixed(2)}%</p>
                            <p className="test-base"> avg. proft</p>
                        </div>
                    </div>
                </div>

                <div className="leaderboard-card-user-rank">
                    <h1 className="text-hero">#{index + 1}</h1>
                </div>
            </div>

            <div className="leaderboard-card-data-container">
                <div className="leaderboard-card-data">
                    <h1 className="text-small text-grey">Trades</h1>
                    <p className="text-base">{cardInfo.tradeCount}</p>
                </div>
                <div className="leaderboard-card-data">
                    <h1 className="text-small text-grey">Biggest Win</h1>
                    <p className="text-base">{cardInfo.largestProfit.toFixed(2)}%</p>
                </div>
                <div className="leaderboard-card-data">
                    <h1 className="text-small text-grey">Strategy</h1>
                    <p className="text-base">{cardInfo.strategy}</p>
                </div>
            </div>

        </div>
    );
}