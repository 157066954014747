import React, { useEffect, useCallback, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { columns } from "./columns";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import Spinner from "../../components/loadingAnimations/Spinner";
import "./TradeHistory.css";

import { useTheme } from "../../hooks/useTheme";
import { useAuth } from "../../hooks/useAuth";

import { getCoinBaseData, getTransactionsByUserId } from "../../helpers/api";
import { TradeHistoryRow, TradeHistoryTable } from "../../components/Table/TradeHistoryTable";
import TradeInstructions from "../../components/TradeInstructions/TradeInstructions";
import TradingViewWidgetModal from "../../components/TradingViewWidget/TradingViewWidgetModal";
import { removeUnsupportedTokens } from "../../helpers/removeUnsupportedTokens";
import { usePageTracking } from "../../hooks/usePageTracking";


const TradeHistory = () => {

  const { user } = useAuth();

  const tableColumns = columns;

  const [allPositionData, setAllPositionData] = useState(null);

  // const [closedTrades, setClosedTrades] = useState(null);

  const { isMobile } = useTheme();

  const [tokenId, setTokenId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [coinbaseAPIError, setCoinbaseAPIError] = useState(false);
  const [awsAPIError, setAwsAPIError] = useState(false);


  // Page Title Setting for GA4
  usePageTracking();

  const date = new Date();

  // const formatDate = (date) => {
  //   const dateObj = new Date(date.replace(/\s/g, ""));

  //   const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  //   const day =  String(dateObj.getDate()).padStart(2, '0');
  //   const year = dateObj.getFullYear()-2000;

  //   const hours = String(dateObj.getHours()).padStart(2, '0');
  //   const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  //   const seconds = String(dateObj.getSeconds()).padStart(2, '0');

  //   return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  // };


  const fetchDataForTables = useCallback(async () => {
    // this will call both API calls at once and await a return of the promise befor proceeding into the .then
    return Promise.all([
      getTransactionsByUserId(user.userId),
      getCoinBaseData(),
    ]).then(([transactionDataResponse, coinbaseResponse]) => {
      // Deconstructing the reutrn array from .all into the data that is returned from the functions.

      console.log(transactionDataResponse)

      if (transactionDataResponse.status !== 200) {
        setAwsAPIError(true);
        return
      }
      
      const coinbaseData = coinbaseResponse.status === 200 ? coinbaseResponse.data : null;
      if (coinbaseResponse.status !== 200) {
        setCoinbaseAPIError(true);
        console.log("TERMINATING")
        return
      }
      
      const supportedTokenTransactions = removeUnsupportedTokens(transactionDataResponse.data, coinbaseData);

      setAllPositionData(supportedTokenTransactions);

    });
  },[setAllPositionData, user.userId]);

  useEffect(() => {
    fetchDataForTables().then();
  }, [fetchDataForTables])

  const trades = [];
  
  if (allPositionData) {
    // Used to calculate the profit for each token sell. Loop through trades and add new key for each new token found, and sum values of trades under each key
    // Once a "sell is found, calculate and add profit to SELL trade, and delete this key to begin recording for next "grouping" of tokens' trades.
    let tokenProfitObject = {}

    var tradeIndex = 0;
    allPositionData.forEach((trade) => {
      // Calculate profit for trades
      const tokenPair = trade.tokenPair.S;
      var profit = null;

      if (trade.entityType.S === "buy") {
        // if obj has the token key already, add new trade value to list
        if (tokenProfitObject.hasOwnProperty(tokenPair)) {
          tokenProfitObject[tokenPair].push( (trade.amount.N * trade.txnPrice.N) );
        } else {
          tokenProfitObject[tokenPair] = [(trade.amount.N * trade.txnPrice.N)]
        }
      }

      // Sum trade values for token and calculate profit. Delete key when done to start collecting data for the next trade.
      if (trade.entityType.S === "sell") {
        try {
          // Math is being done on the back end now 8/30/2024
          // const sumOfTradeValues = tokenProfitObject[tokenPair].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
          // const sellValueOfTrade = (trade.amount.N * trade.txnPrice.N)
          // profit = ( ( (sellValueOfTrade - sumOfTradeValues) / sumOfTradeValues ) * 100 ).toFixed(2);
          profit = (((trade.txnPrice.N - trade.costBasis.N)/trade.costBasis.N)*100).toFixed(2);

  
          delete tokenProfitObject[tokenPair];
        } catch (error) {
          // Filters out duplicate sell orders
          return
        }

      }

      const tradeData = {
        id: tradeIndex,
        tokenId: tokenPair,
        entityType: trade.entityType.S,
        amount: trade.amount.N,
        // date: formatDate(trade.txnDate.S),
        date: trade.txnDate.S,
        txnPrice: trade.txnPrice.N,
        total: (trade.txnPrice.N * trade.amount.N),
        closePrice: trade.hasOwnProperty("closePrice")
          ? trade.closePrice.N
          : null,
        tradeStatus: trade.isOpen.BOOL ? "open" : "closed",
        txnSource: trade.hasOwnProperty("txnSource")
          ? trade.txnSource.S
          : "Trading View",
        profit: profit,
      };
      if (trade.entityType.S !== "reconcile") {

        trades.push(tradeData);
        tradeIndex++;
      }
    });
  }
  
  if (isMobile) {
    return(
      <DashboardLayout page={"Trade History"}>
        <div className="trade-history">
          {!awsAPIError && ! coinbaseAPIError ? 
            (
              <div className="trade-history-content">
              {/* {closedTrades &&
                <Activity closedTrades={closedTrades} />
              } */}
              {trades && trades.length > 0 ? (
                <TradeHistoryTable>
                  {trades.map((item, index) => (
                    <TradeHistoryRow
                      key={index}
                      token={item.tokenId}
                      action={item.entityType}
                      amount={item.amount}
                      price={item.txnPrice}
                      total={item.total}
                      profit={item.profit}
                      date={item.date}
                      source={item.txnSource}
                    />
                  ))}
                </TradeHistoryTable>
              ) : trades.length === 0 ? (
                <TradeInstructions />
              ) : (
                <Spinner containerClass={"spinner-table-container"} />
              )}
            </div>
          ) : (
            <div className="trade-history-error">Can't fetch token pricing. Please try again shortly.</div>
          )}
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout page={"Trade History"}>
      <div className="trade-history">
        <div className="trade-history-content glass-surface-module">
          {!awsAPIError && !coinbaseAPIError ?
            (
              <div className="trade-history-table">
                {/* {closedTrades &&
                  <Activity closedTrades={closedTrades} />
                } */}
                {allPositionData && (
                  <p className="trade-history-updated-time">
                    last update {date.toString()}
                  </p>
                )}
                {allPositionData && allPositionData.length > 0 ? (
                  <DataGrid
                    // Hide id column
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          status: false,
                          id: false,
                        },
                      },
                      sorting: {
                        sortModel: [{ field: "date", sort: "desc" }],
                      },
                    }}
                    rows={trades}
                    columns={tableColumns}
                    slots={{ toolbar: GridToolbar }}
                    // onCellClick={handleCellClick}
                  />
                ) : allPositionData && allPositionData.length === 0 ? (
                  <TradeInstructions />
                ) : (
                  <Spinner containerClass={"spinner-table-container"} />
                )}
              </div>
            ) : (
              <div className="trade-history-error">Can't fetch token pricing. Please try again shortly.</div>
            )
          }
        </div>
      </div>
      {showModal && tokenId && <TradingViewWidgetModal tokenId={tokenId} setShowModal={setShowModal} />}
    </DashboardLayout>
  );
};

export default TradeHistory;
