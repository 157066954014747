import React, { useEffect, useReducer, useRef, useState } from "react";
import "./OpenPositionsTable.css";

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


export const OpenPositionsTable = ({openPositionData}) => {

    const [isAscending, setIsAscending] = useState(false);
    const [sortParam, setSortParam] = useState("id");

    const [tableData, setTableData] = useState([...openPositionData]);

    const handleSortClick = (value) => {
        setIsAscending(!isAscending);
        setSortParam(value);
    };

    const sortData = (dataArray, sortParam, order = "desc") => {
        const sortedArray = [...dataArray];
        return sortedArray.sort((a,b) => {
            if ( order === "asc" ) {
                if ( a[sortParam] < b[sortParam] ) return -1;
                if ( a[sortParam] > b[sortParam] ) return 1;
            } else if ( order === "desc" ) {
                if ( a[sortParam] > b[sortParam] ) return -1;
                if ( a[sortParam] < b[sortParam] ) return 1;
            }
            return 0; // If a[sortParam] == b[sortParam];
        });
    }

    const prevSortParam = useRef(sortParam);
    const prevIsAscending = useReducer(isAscending);

    useEffect(() => {
        if (prevSortParam.current !== sortParam || prevIsAscending.current !== isAscending) {
            const sortedTableData = sortData(openPositionData, sortParam, isAscending ? "asc" : "desc");
            setTableData(sortedTableData);
            prevSortParam.current = sortParam;
            prevIsAscending.current = isAscending;
        }
    }, [sortParam, openPositionData, isAscending]);
    
    return(
        <div className="op-table">
            <div className="op-table-header">
                <div className="op-table-header-container" onClick={() => handleSortClick("id")}>
                    <p>Name</p>
                    {sortParam === "id" && (isAscending ? <ArrowUpwardIcon fontSize="small"/> : <ArrowDownwardIcon fontSize="small"/>)}
                </div>
                <div className="op-table-header-container" onClick={() => handleSortClick("amount")}>
                    <p>Value</p>
                    {sortParam === "amount" && (isAscending ? <ArrowUpwardIcon fontSize="small"/> : <ArrowDownwardIcon fontSize="small"/>)}
                </div>
                <div className="op-table-header-container" onClick={() => handleSortClick("profit")}>
                    <p>Profit</p>
                    {sortParam === "profit" && (isAscending ? <ArrowUpwardIcon fontSize="small"/> : <ArrowDownwardIcon fontSize="small"/>)}
                </div>
            </div>
            {tableData && tableData.map((item, index) => (
                <OpenPositionsRow 
                    key={index} 
                    token={item.id}
                    amount={item.amount}
                    costBasis={item.avgPrice * item.amount}
                    currentPrice={item.currentPrice}
                    currentValue={item.amount * item.currentPrice}
                    profit={item.profit}
                    avgPrice={item.avgPrice}
                />
            ))}
        </div>
    );
};

export const OpenPositionsRow = ({token, amount, currentPrice, costBasis, currentValue, profit, avgPrice}) => {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        return () => setShowModal(false); //Cleanup on unmount
    }, [])

    return(
        <>
            <div className="op-row-wrapper" onClick={() => setShowModal(true)}>
                <div className="op-token-container">
                    <p className="op-token op-row-title">{token}</p>
                </div>

                <div className="op-value-container">
                    <p className="op-usd-value op-row-title">
                        {currentValue.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                    </p>
                    <div className="op-token-amount-container">
                        <p className="op-token-amount op-row-base">
                            {amount.toFixed(6)} {token}
                        </p>
                    </div>
                </div>

                <div className="op-profit-container">
                    <p className={`op-profit op-row-title ${profit >= 0 ? "op-profit-pos" : "op-profit-neg"}`}>{profit.toFixed(2)}%</p>
                </div>
            </div>
            {showModal &&
                <OpenPositionsModal
                    token={token}
                    amount={amount}
                    costBasis={costBasis}
                    currentPrice={currentPrice}
                    currentValue={currentValue}
                    profit={profit}
                    avgPrice={avgPrice}
                    setShowModal={setShowModal}
                />
            }
        </>
    );
};

export const OpenPositionsModal = ({token, amount, costBasis, currentPrice, currentValue, profit, avgPrice, setShowModal}) => {
    return(
        <div className="op-modal">
            <div className="op-modal-header">
                <h1 className="op-modal-header-title">Position Details</h1>
                <div className="op-modal-close" onClick={() => setShowModal(false)}>
                    X
                </div>
            </div>
            <div className="op-modal-content">
                <div className="op-modal-row">
                    <p className="op-modal-row-title">
                        Pair
                    </p>
                    <p className="op-modal-row-base">
                        {token}-USD
                    </p>
                </div>
                <div className="op-modal-row">
                    <p className="op-modal-row-title">
                        Amount
                    </p>
                    <p className="op-modal-row-base">
                        {amount} {token}
                    </p>
                </div>
                <div className="op-modal-row">
                    <p className="op-modal-row-title">
                        Average Price
                    </p>
                    <p className="op-modal-row-base">
                        {avgPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                    </p>
                </div>
                <div className="op-modal-row">
                    <p className="op-modal-row-title">
                        Current Price
                    </p>
                    <p className="op-modal-row-base">
                        {currentPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                    </p>
                </div>
                <div className="op-modal-row">
                    <p className="op-modal-row-title">
                        Cost Basis
                    </p>
                    <p className="op-modal-row-base">
                        {costBasis.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                    </p>
                </div>
                <div className="op-modal-row">
                    <p className="op-modal-row-title">
                        Current Value
                    </p>
                    <p className="op-modal-row-base">
                        {currentValue.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                    </p>
                </div>
                <div className="op-modal-row">
                    <p className="op-modal-row-title">
                        Profit
                    </p>
                    <p className="op-modal-row-base">
                        {profit ? `${parseFloat(profit).toFixed(2)}%` : "N/a"}
                    </p>
                </div>
            </div>
        </div>
    );
};