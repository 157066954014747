import React, { useEffect, useCallback, useState, useRef } from "react";

import "./ManualTrade.css";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";

import { getCoinBaseData, getTransactionsByUserId, manualTrade, getUserBalance, getOpenPositionsByUserId } from "../../helpers/api";

import { formatDataForGrid } from "../../helpers/formatOpenPostions";

import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "../../hooks/useTheme";

import Button from "../../components/primitives/Button/Button";

import TradeOrderInput from "./TradeOrderInput";
import TradingViewWidget from "../../components/TradingViewWidget/TradingViewWidget";
import TradeAction from "./TradeAction";
import Spinner from "../../components/loadingAnimations/Spinner";
import DropDownHandler from "./DropDownHandler";
import { removeUnsupportedTokensForDropDown } from "../../helpers/removeUnsupportedTokens";
import { usePageTracking } from "../../hooks/usePageTracking";


const ManualTrade = () => {

  const { user } = useAuth();
  const { isMobile } = useTheme();

  const [isInputValid, setIsInputValid] = useState(true);

  const [userHoldings, setUserHoldings] = useState(null);
  const [coinbaseData, setCoinbaseData] = useState(null);

  const [dropDownTokens, setDropDownTokens] = useState(null);

  const [tokenChoice, setTokenChoice] = useState('BTC');
  const [tokenHoldingAmount, setTokenHoldingAmount] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);

  const [tradeOption, setTradeOption] = useState("buy");
  const [isUSD, setIsUSD] = useState(true);

  const [tradeAmountUSD, setTradeAmountUSD] = useState(0);
  const [tradeAmountCrypto, setTradeAmountCrypto] = useState(0);

  const [userBalance, setUserBalance] = useState(0);

  const [showSpinner, setShowSpinner] = useState(false);
  const [showTradeResponseStatus, setShowTradeResponseStatus] = useState(false);
  const [tradeStatusMessage, setTradeStatusMessage] = useState(null);

  const [coinbaseAPIError, setCoinbaseAPIError] = useState(false);

  // Page Title Setting for GA4
  usePageTracking();

  // Fetch the most up to date user holdings and coinbase price data
  const fetchUserHoldings = useCallback(async () => {
    return Promise.all([
      getOpenPositionsByUserId(user.userId),
      getCoinBaseData(),
      getUserBalance(user.userId),
    ]).then(([transactionDataResponse, coinbaseResponse, userBalance]) => {

      setUserBalance(parseFloat(userBalance));
      
      const coinbaseData = coinbaseResponse.status === 200 ? coinbaseResponse.data : null;
      if (coinbaseResponse.status !== 200) {
        setCoinbaseAPIError(true);
        return
      }

      if (transactionDataResponse.status !== 200) {
        return
      }
      setCoinbaseData(coinbaseData);

      if (!tokenPrice) {
        setTokenPrice( 1 / coinbaseData.data.rates["BTC"])
      }
      const userHoldingsObj = {};
      const formatedTransactionData = formatDataForGrid(
        transactionDataResponse.data,
        coinbaseData
      );
      // console.log("Formated Transaction Data", formatedTransactionData);
      formatedTransactionData.forEach((token) => {
        const tokenName = token.id.split("USD")[0].trim();
        userHoldingsObj[tokenName] = { amount: token.amount };
      });

      // If user has holdings, populate object for sell drop down
      if (Object.keys(userHoldingsObj).length > 0) {
        setDropDownTokens({userHoldings: removeUnsupportedTokensForDropDown(coinbaseData, Object.keys(userHoldingsObj))});
        setUserHoldings(userHoldingsObj);
      } else {
        setDropDownTokens({userHoldings: []})
        setUserHoldings({});
      }

      // Since current token on page load is BTC, look to see if user has BTC holdings and update the current holdinfs value.
      if (userHoldingsObj.hasOwnProperty(tokenChoice)) {
        setTokenHoldingAmount(userHoldingsObj[tokenChoice]["amount"]);
      } else {
        setTokenHoldingAmount(0);
      }
    });
  }, [tokenChoice, tokenPrice, user.userId]);

  const handleTokenSelect = useCallback((token) => {

    setTokenChoice(token);
    setTradeAmountUSD(0);
    setTradeAmountCrypto(0);
    // check if user has holdings of the token
    if (userHoldings && Object.keys(userHoldings).length > 0 && userHoldings.hasOwnProperty(token)) {
      setTokenHoldingAmount(userHoldings[token]["amount"]);
    } else {
      setTokenHoldingAmount(0);
    }

  }, [userHoldings]);

  const handleAmountChange = (amount, currencyType) => {
    if (currencyType === "USD") {
      setTradeAmountUSD(amount);
      setTradeAmountCrypto(amount/tokenPrice);
    } else if (currencyType === "CRYPTO") {
      setTradeAmountUSD(amount * tokenPrice);
      setTradeAmountCrypto(amount);
    }
  }

  // Using Crypto Amount in trade api call
  const handleOrderSubmit = async () => {
    if (isInputValid && tradeAmountCrypto > 0) {
      setShowSpinner(true);

      const tradeData = {
        tokenpair: tokenChoice + "USD",
        count: tradeAmountCrypto,
        usdprice: tokenPrice,
        action: tradeOption,
        exchange: "Coinbase",
        user: user.userId,
        pin: user.webhookId,
      };

      return Promise.all([
        manualTrade(tradeData)
      ]).then(([tradeResponse]) => {
        setShowSpinner(false);

        setShowTradeResponseStatus(true);
        setTradeStatusMessage(tradeResponse.status === 200 ? "Sucess!" : "Server Error");

        setTimeout(() => {
            setShowTradeResponseStatus(false);
            setTradeStatusMessage(null);
        }, 2000);

        if (tradeResponse.status === 200) {

          if (tradeOption === "buy") {
            setTokenHoldingAmount(tokenHoldingAmount + tradeAmountCrypto);
            setUserBalance(parseFloat(userBalance - tradeAmountUSD));

          } else if (tradeOption === "sell") {
            setTokenHoldingAmount(tokenHoldingAmount - tradeAmountCrypto);
            setUserBalance(parseFloat(userBalance + tradeAmountUSD));
          }
          // Resetting the amount field after buy/sell
          setTradeAmountCrypto(0);
          setTradeAmountUSD(0);
          fetchUserHoldings();
        }
      });
    }
  }

  useEffect(() => {
      fetchUserHoldings().then();
  }, [tradeOption, fetchUserHoldings]);

  useEffect(() => {

    if (tokenChoice && coinbaseData) {
      try {
        setTokenPrice(1 / coinbaseData.data.rates[tokenChoice])
      } catch (error) {
        console.warn("could not set token price")
      }
    }
  
    // set user holding amount if user has token
    if (userHoldings && Object.keys(userHoldings).length > 0 && userHoldings.hasOwnProperty(tokenChoice)) {
      setTokenHoldingAmount(parseFloat(userHoldings[tokenChoice]["amount"]));
    }

    // Need to null check here since first load of page triggers token select and Coinbase data may not have been received yet
    if (!coinbaseData) {
      return
    }
    setTokenPrice( parseFloat(1 / coinbaseData.data.rates[tokenChoice]));

  },[coinbaseData, tokenChoice, userHoldings]);

  const prevTradeOptionRef = useRef(tradeOption);

  useEffect(() => {
    if (prevTradeOptionRef.current !== tradeOption) {
      prevTradeOptionRef.current = tradeOption
      if (tradeOption === "sell") {
        // When Sell is selected, handles changing data to the first Token in the users current holdings.
        if (dropDownTokens && dropDownTokens.userHoldings.length > 0) {
          handleTokenSelect(dropDownTokens.userHoldings[0]);
          if (userHoldings.hasOwnProperty(dropDownTokens.userHoldings[0])) {
            const newHoldingAmount = parseFloat(userHoldings[dropDownTokens.userHoldings[0]]["amount"]);
            if (tokenHoldingAmount !== newHoldingAmount) {
              setTokenHoldingAmount(newHoldingAmount);  // Only update if the value changes
            }
          }
          if (coinbaseData && tokenPrice !== 1 / coinbaseData.data.rates[dropDownTokens.userHoldings[0]]) {
            setTokenPrice( 1 / coinbaseData.data.rates[dropDownTokens.userHoldings[0]])
          }
        }
      }
    }
  },[tradeOption, userHoldings, coinbaseData, dropDownTokens, handleTokenSelect, tokenHoldingAmount, tokenPrice]);

  return (
    <DashboardLayout page={"Manual Trade"}>
      <div className="manual-trade">
        <div className="manual-trade-content">
          <div className="manual-trade-container">
            <div className="manual-trade-dashboard">
              <div className="chart-container">
                <div className="chart-coin-select-container glass-surface">
                  {(coinbaseData && dropDownTokens) && 
                    <DropDownHandler 
                      tradeOption={tradeOption}
                      dropDownTokens={dropDownTokens}
                      currentSelection={tokenChoice}
                      coinbaseData={coinbaseData}
                      handleTokenSelect={handleTokenSelect}
                    />
                  }
                </div>
                <div className="chart-data-display glass-surface">
                  {tokenChoice && <TradingViewWidget tokenChoice={tokenChoice}/>}
                </div>
              </div>

              {/* Manual Trade Options */}
              <div className="manual-trade-action-container glass-surface">
                {!coinbaseAPIError ? (
                  <>
                    {/* Buy and Sell Buttons */}
                    <TradeAction tradeOption={tradeOption} setTradeOption={setTradeOption}/>

                    {/* Available To Trade */}
                    <div className="available-wrapper">
                      {isMobile ? (
                        <div className="available-container-mobile">
                          <h3>Available to trade</h3>
                          {tradeOption === "buy" ? (
                            <p>{userBalance.toLocaleString("en-US", {style: "currency", currency: "USD",})}</p>
                          ) : (
                            <p>{tokenHoldingAmount.toFixed(8)} {tokenChoice}</p>
                          )}

                        </div>
                      ) : (
                        <div className="available-container">
                          <h3>Available to trade</h3>
                          <div className="available-token-wrapper">
                            <h4>{tokenChoice}</h4>
                            <p>{tokenHoldingAmount}</p>
                          </div>
                          <div className="available-usd-wrapper">
                            <h4>USD</h4>
                            <p>{userBalance.toLocaleString("en-US", {style: "currency", currency: "USD",})}</p>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Trade Information */}
                    <div className="trade-information-wrapper">
                      { tokenChoice && 
                        <TradeOrderInput 
                          // isReadOnly={tradeOption === "sell"}
                          tradeOption={tradeOption}
                          userTokenAmount={tokenHoldingAmount}
                          isReadOnly={false}
                          tokenChoice={tokenChoice}
                          tokenPrice={tokenPrice} 
                          userBalance={userBalance} 
                          isUSD={isUSD}
                          setIsUSD={setIsUSD}
                          tradeAmountCrypto={tradeAmountCrypto}
                          amount={isUSD ? tradeAmountUSD : tradeAmountCrypto}
                          handleAmountChange={handleAmountChange}
                          isInputValid={isInputValid}
                          setIsInputValid={setIsInputValid}
                        />
                      }
                    </div>

                    <div className="trade-button-wrapper">
                      <div className="trade-button-container">
                        {isMobile ? (
                          <>
                            <div className="trade-total-amount">
                              <h4>Total</h4>
                              <p>{parseFloat(tradeAmountUSD).toLocaleString("en-US", {style: "currency", currency: "USD",})}</p>
                            </div>
                          </>

                        ) : (
                          <>
                            <div className="trade-total-wrapper">
                              <h4>{tokenChoice}*</h4>
                              <p>{tokenPrice > 1 ? `${tokenPrice.toLocaleString("en-US", {style: "currency", currency: "USD",})}` : `$${tokenPrice.toFixed(4)}`}</p>
                            </div>
                            <div className="trade-total-wrapper">
                              <h4>{tokenChoice} Amount</h4>
                              <p>{tradeAmountCrypto}</p>
                            </div>
                            <div className="trade-total-amount">
                              <h4>Total</h4>
                              <p>{parseFloat(tradeAmountUSD).toLocaleString("en-US", {style: "currency", currency: "USD",})}</p>
                            </div>
                          </>
                        )}
                        {!showSpinner ? (
                          <Button kind={`trade-${tradeOption}`} onClick={handleOrderSubmit}>{tradeOption.toUpperCase()}</Button>
                        ) : (
                          <Button kind={`trade-${tradeOption}`}>
                            <Spinner />
                          </Button>
                        )}
                        {showTradeResponseStatus && tradeStatusMessage &&
                          <div className="tv-widget-trade-status-alert">
                              <p className={`text-header ${tradeStatusMessage === "Server Error" ? "text-trading-red" : ""}`}>{tradeStatusMessage}</p>
                          </div>
                        }
                      </div>
                    </div>
                    {!isMobile && 
                      <div className="manual-trade-action-footer">
                        <p>* Token Price data may be delayed up to 15 Minutes</p>
                      </div>
                    }
                  </>
                ) : (
                  <div className="tv-widget-modal-container-trade-disabled">
                    <p>Error getting token data. Please try again shortly.</p>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ManualTrade;
