import React from "react";

import "../styles/CardModal.css"

import LineChart from "../../nivoCharts/LineChart";

const PortfolioValueModal = ({toggleModal, portfolioValue, netProfitPercent, lineChartData}) => {

    const chartSettings = {
        margin: { top: 20, right: 30, bottom: 50, left: 55 }
    }

    return(
        <div className="modal" onClick={toggleModal}>
            <div className="modal-card" onClick={e => e.stopPropagation()}>
                <h2 className="modal-card-title">PortfolioValue</h2>
                <div className="modal-card-info">
                    <div className="modal-card-position-info">
                        <h1 className="modal-card-position-value">{portfolioValue.toLocaleString("en-US", {style: "currency", currency: "USD"})}</h1>
                        <p className="card-position-value-subtext text-small" style={netProfitPercent >0 ? {color: "green"} : {color: "red"} }>{netProfitPercent.toFixed(2)}%</p>
                    </div>
                    <div className="modal-card-chart-display">
                        <LineChart lineChartData={lineChartData} chartSettings={chartSettings} />
                    </div>
                </div>
            </div>    
        </div>
    );
};

export default PortfolioValueModal;
