import React from "react";

import "./ContactUs.css";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { usePageTracking } from "../../hooks/usePageTracking";


const ContactUs = () => {
  usePageTracking();
  
  return (
    <DashboardLayout page={"Contact Us"}>
      <div className="contact-us">
        <div className="contact-us-content glass-surface-module">
          <p>
            We're here to assist you! If you have any questions, concerns, or just want to get in touch, feel free to <a className="contact-us-email-address" href="https://docs.chart.observer/contact-us/" target="_blank" rel= "noreferrer">Contact Us</a>.
          </p>
          <p>
            We also encourage you to check out the <a target='_blank' rel= "noreferrer" href='https://discord.com/invite/5M4CbvdMVd'>Discord server</a>.  As our community grows, we hope it will provide an opportunity for 
            our users to connect with each other and share their knoweldge of our platform, as well as crypto in general.  
          </p>
          <p>  
            Please remember
            that the opinions of user on our Discord server are their own and do not necessarily represent the views of Chart Observer.
          </p>
          <p>
            We value your feedback and are committed to providing you with the best assistance possible.
          </p>
          <p>
            Thank you for choosing Chart Observer!
          </p>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ContactUs;
