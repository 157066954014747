import React, { useState } from "react";
import "./ButtonGroup.css";

export const ButtonGroup = ({options, setCurrentButton}) => {

    const [activeButton, setActiveButton] = useState(options[0]);

    const handleChange = (buttonOption) => {
        setActiveButton(buttonOption);
        setCurrentButton(buttonOption);
    }

    return(
        <div className="button-group-container">
            {options.map((buttonOption) => (
                <ButtonGroupButton 
                    key={buttonOption} 
                    buttonOption={buttonOption} 
                    isActive={activeButton === buttonOption}
                    onClick={() => handleChange(buttonOption)}
                />
            ))}
        </div>
    );
}

const ButtonGroupButton = ({buttonOption, isActive, onClick}) => {
    return(
        <div 
            className={`button-group-button-container ${isActive ? "button-group-button-container-active" : ""}`}
            onClick={onClick}
        >
            <h1 className="text-base">{buttonOption}</h1>
        </div>
    );
}