import { Typography } from "@mui/material";

const fontSize = 14;

export const columns = [
    { 
        field: "id", 
        headerName: "Trade Index",
        flex: 0.5,
        cellClassName: "--cell"
    },
    { 
        field: "tokenId", 
        headerName: "Token", 
        flex: 1,
        cellClassName: "--cell --token-cell"
    },
    {  
        field: "entityType", 
        headerName: "Action",
        flex: 0.5,
        cellClassName: "--cell",
    },
    { 
        field: "amount", 
        headerName: "Amount",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({row: {amount}}) => {
            return(
                <Typography fontSize={fontSize} >
                    {parseFloat(amount).toFixed(6)}
                </Typography>
            )
        }
    },
    { 
        field: "txnPrice", 
        headerName: "Price",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: {txnPrice}}) => {
            return(
                <Typography fontSize={fontSize}>
                    {parseFloat(txnPrice) < 1 ? `$${parseFloat(txnPrice).toFixed(6)}` : parseFloat(txnPrice).toLocaleString("en-US", {style: "currency", currency: "USD"})}
                </Typography>
            );
        }
    },
    // { 
    //     field: "closePrice", 
    //     headerName: "Close Price",
    //     flex: 1,
    //     cellClassName: "--cell",
    //     renderCell: ({ row: {closePrice}}) => {
    //         return(
    //             <Typography fontSize={fontSize}>
    //                 { closePrice ? parseFloat(closePrice).toLocaleString("en-US", {style: "currency", currency: "USD"}) : ""}
    //             </Typography>
    //         );
    //     }
    // },
    { 
        field: "total", 
        headerName: "Total",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: {total}}) => {
            return(
                <Typography fontSize={fontSize}>
                    { parseFloat(total).toLocaleString("en-US", {style: "currency", currency: "USD"}) }
                </Typography>
            );
        }
    },
    // Hidding Trade Status for now
    // {  
    //     field: "tradeStatus", 
    //     headerName: "Status",
    //     flex: 1,
    //     cellClassName: "--cell",
    // },
    { 
        field: "profit", 
        headerName: "Profit",
        flex: 1,
        renderCell: ({ row: { profit }}) => {
            if (profit){
                const profitColor = profit >= 0
                    ? "green"
                    : "red"
                return(
                    <Typography color={profitColor} fontSize={fontSize}>
                        {profit}%
                    </Typography>
                );
            } else {
                return(
                    <Typography fontSize={fontSize}>
                        {}
                    </Typography>
                );
            }
        }
    },
    {  
        field: "date", 
        headerName: "Date",
        flex: 2,
        cellClassName: "--cell",
        renderCell: ({ row: { date }}) => {
            // Parse the UTC date string
            // Adding Z to end of date since it is in UTC but we dont return that
            const utcDate = new Date(date+"Z");

            // Format the date in the current time zone
            const formatter = new Intl.DateTimeFormat('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false, // Use 24-hour format
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });

            const formattedDate = formatter.format(utcDate);
            return(
                <Typography fontSize={fontSize}>
                    {formattedDate}
                </Typography>
            )
        }
    },
    {  
        field: "txnSource", 
        headerName: "Source",
        flex: 1,
        cellClassName: "--cell",
    },
]
