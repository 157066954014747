import React, { useState } from "react";

import "./styles/Card.css";
import PieChart from "../nivoCharts/PieChart";
import HoldingsModal from "./modals/HoldingsModal";

import { useTheme } from "../../hooks/useTheme";

const HoldingsCard = ({ openPositionData, userBalance}) => {

  const [modalState, setModalState] = useState(false);

  const { isMobile } = useTheme();

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const colors = [
    "#C6DAEF",
    "#9ECAE0",
    "#6AAED6",
    "#4192C5",
    "#2270B5",
    "#08519C",
    "#0A306B",
    "#F7FBFF",
    "#DDEBF7",
  ];
  const newArray = Array.from(openPositionData);
  newArray.forEach((token) => {
    token.id = token.id.split("USD")[0];
  });
  // Adding cash to the holding pie
  newArray.push({ id: "USD", totalCost: userBalance });
  // sort array on descending value
  newArray.sort((a, b) => b.totalCost - a.totalCost);

  const tokenList = [];
  const chartData = [];
  newArray.forEach((token, index) => {
    tokenList.push(token.id);
    chartData.push({
      id: token.id,
      label: token.id,
      value: token.id === "USD" ? token.totalCost : (token.amount * token.currentPrice),
      color: colors[index],
    });
  });

  const chartSettings = {
    margin: { top: 20, right: 40, bottom: 20, left: 40 },
    arcLinkLabelsSkipAngle: 10,
    arcLinkLabelsDiagonalLength: 10,
    arcLinkLabelsStraightLength: 8,
    enableArcLabels: false,
  };

  return (
    <>
      <div className="card glass-surface-module" onClick={() => !isMobile && toggleModal()}>
        <h1 className="text-header">Allocation</h1>
        <div className="card-info">
          <p className="card-position-value text-base">
            {parseFloat(userBalance).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
            {" USD*"}
            <span className="text-base"> available</span>
          </p>
          <div className="card-position-info">
          </div>
          {/* Nivo Pie Chart goes here */}
          <div className="card-chart-display">
            <PieChart chartData={chartData} chartSettings={chartSettings} />
          </div>
        </div>
      </div>
      {modalState && (
        <HoldingsModal
          toggleModal={toggleModal}
          chartData={chartData}
          tokenList={tokenList}
        />
      )}
    </>
  );
};

export default HoldingsCard;
